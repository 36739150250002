import { template as template_f3c0000ace964a5f9d8e88e1faea6d14 } from "@ember/template-compiler";
const FKText = template_f3c0000ace964a5f9d8e88e1faea6d14(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
