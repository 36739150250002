import { template as template_e188ed939aea4881ae9ab254d16bd766 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e188ed939aea4881ae9ab254d16bd766(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
