import { template as template_8e8cb291c8804c7da7ca27f98921bfd3 } from "@ember/template-compiler";
const WelcomeHeader = template_8e8cb291c8804c7da7ca27f98921bfd3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
