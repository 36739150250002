import { template as template_98df411e39cf42e0b956f34f4fd0277e } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_98df411e39cf42e0b956f34f4fd0277e(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_98df411e39cf42e0b956f34f4fd0277e(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_98df411e39cf42e0b956f34f4fd0277e(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
